import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

const useSocketMatchSettings = (matchId) => {
  let userId = localStorage.getItem("UUID");
  const [data, setData] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (
      matchId === 0 ||
      matchId === null ||
      matchId === undefined ||
      matchId === ""
    ) {
      return;
    }

    socketRef.current = io("https://api.jmd10.com");

    const handleConnect = () => {
      console.log("Connected to server");

      socketRef.current.emit("match_settings", {
        userId: userId,
        matchId: matchId,
      });

      const handleSettingsData = (settingsData) => {
        if (settingsData === null) {
          setTimeout(() => {
            window.location.href = "/inplay";
          }, 1000);
          return;
        }
        setData(settingsData);
      };

      socketRef.current.on(`settings_${userId}_${matchId}`, handleSettingsData);

      return () => {
        socketRef.current.off(
          `settings_${userId}_${matchId}`,
          handleSettingsData
        );
      };
    };

    const handleError = (error) => {
      console.error("Socket error: ", error);
    };

    socketRef.current.on("connect", handleConnect);
    socketRef.current.on("error", handleError);

    return () => {
      socketRef.current.disconnect();
      socketRef.current.off("connect", handleConnect);
      socketRef.current.off("error", handleError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId]);

  return data;
};

export default useSocketMatchSettings;
